import React, { Fragment } from "react";
import styled from "styled-components";

import { Name, Title } from "../components/text";

import FooterLinks from "../components/footerLinks";

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${Name} {
    margin-bottom: 20px;
  }
`;

function Home() {
  return (
    <Fragment>
      <TopContainer>
        <Name>Lee Allen</Name>
        <Title>Software Engineer</Title>
      </TopContainer>
      <FooterLinks />
    </Fragment>
  );
}

export default Home;
