import { trackCustomEvent } from "gatsby-plugin-google-analytics";
import React from "react";
import styled from "styled-components";

import { EmailIcon, FileIcon, GitHubIcon, LinkedInIcon } from "./icons";
import Link from "./link";
import resume from "../assets/files/Resume_Lee_Allen.pdf";

const FooterContainer = styled.div`
  align-items: flex-end;
  display: flex;
  flex-direction: column;

  ${Link}:not(:last-child) {
    margin-bottom: 20px;
  }

  @media screen and (min-width: 768px) {
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;

    ${Link}:not(:last-child) {
      margin-bottom: 0;
      margin-right: 30px;
    }
  }
`;

function FooterLinks(props) {
  const { className } = props;

  return (
    <FooterContainer className={className}>
      <Link href="mailto:lee@leeallen.me">
        <EmailIcon
          onClick={() => {
            trackCustomEvent({
              action: "Click",
              category: "Icon Click",
              label: "Email",
            });
          }}
          title="email"
        />
      </Link>
      <Link href={resume} rel="noopener noreferrer" target="_blank">
        <FileIcon
          onClick={() => {
            trackCustomEvent({
              action: "Click",
              category: "Icon Click",
              label: "Resume",
            });
          }}
          title="resume"
        />
      </Link>
      <Link
        href="https://github.com/leeallen337/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <GitHubIcon
          onClick={() => {
            trackCustomEvent({
              action: "Click",
              category: "Icon Click",
              label: "GitHub",
            });
          }}
          title="github"
        />
      </Link>
      <Link
        href="https://www.linkedin.com/in/leeallen337/"
        rel="noopener noreferrer"
        target="_blank"
      >
        <LinkedInIcon
          onClick={() => {
            trackCustomEvent({
              action: "Click",
              category: "Icon Click",
              label: "LinkedIn",
            });
          }}
          title="linkedin"
        />
      </Link>
    </FooterContainer>
  );
}

export default FooterLinks;
