import styled from "styled-components";

const Text = styled.div`
  font-size: 2em;
  font-weight: 700;

  @media screen and (min-width: 768px) {
    font-size: 3em;
  }
`;

const Name = styled(Text)`
  color: ${({ theme }) => theme.blue};
`;

const Title = styled(Text)`
  color: ${({ theme }) => theme.blueDark};
`;

export { Name, Title };
