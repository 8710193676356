import styled from "styled-components";

import {
  EmailIcon,
  FileIcon,
  GitHubIcon,
  LinkedInIcon,
} from "../components/icons";

const Link = styled.a`
  cursor: pointer;
  display: flex;
  text-decoration: none;

  &:hover,
  &:focus {
    ${EmailIcon},
    ${FileIcon},
    ${GitHubIcon},
    ${LinkedInIcon} {
      color: ${({ theme }) => theme.blueDark};
      transition: all .2s;
    }
  }
`;

export default Link;
