import {
  FileTextOutlined,
  GithubOutlined,
  LinkedinOutlined,
  MailOutlined,
} from "@ant-design/icons";
import styled from "styled-components";

const EmailIcon = styled(MailOutlined)`
  display: flex;
  color: ${({ theme }) => theme.blue};
  font-size: 2em;

  @media screen and (min-width: 768px) {
    font-size: 2.5em;
  }
`;

const FileIcon = styled(FileTextOutlined)`
  display: flex;
  color: ${({ theme }) => theme.blue};
  font-size: 2em;

  @media screen and (min-width: 768px) {
    font-size: 2.5em;
  }
`;

const GitHubIcon = styled(GithubOutlined)`
  display: flex;
  color: ${({ theme }) => theme.blue};
  font-size: 2em;

  @media screen and (min-width: 768px) {
    font-size: 2.5em;
  }
`;

const LinkedInIcon = styled(LinkedinOutlined)`
  display: flex;
  color: ${({ theme }) => theme.blue};
  font-size: 2em;

  @media screen and (min-width: 768px) {
    font-size: 2.5em;
  }
`;

export { EmailIcon, FileIcon, GitHubIcon, LinkedInIcon };
